<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col lg:w-1/2 w-full">
        <div class="vx-card mb-base mr-0">
          <div class="vx-card__header">
            <div class="vx-card__title"><h4 class="">Access List</h4>
              <!---->
            </div><!---->
          </div>
          <div class="vx-card__collapsible-content vs-con-loading__container">
            <div class="vx-card__body">
              <vs-list v-for="(user, key) in activeInvitations" :key="key">
                <vs-list-item :title="user.parentDetails.fullName"></vs-list-item>
              </vs-list>
            </div><!---->
          </div>
          <div class="vx-card__code-container collapsed" style="max-height: 0px; display: none;">
            <div class="code-content">
              <pre class="language-markup"><code class="language-markup"></code></pre>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <div class="vx-card mb-base ml-0">
          <div class="vx-card__header">
            <div class="vx-card__title"><h4 class="">Invite Pending Links</h4>
              <!---->
            </div><!---->
          </div>
          <div class="vx-card__collapsible-content vs-con-loading__container">
            <div class="vx-card__body">
              <vs-list v-for="(user, key) in pendingInvitations" :key="key+0.1">
                <vs-list-item :title="user.parentDetails.fullName"></vs-list-item>
              </vs-list>
            </div><!---->
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col lg:w-1/2 w-full">

      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <a href="#" class="vs-component vs-button mb-md-0 mb-2 vs-button-primary vs-button-filled" @click="invite">Invite</a>
        <a href="#" class="vs-component vs-button mb-md-0 mb-2 ml-2 vs-button-primary vs-button-filled" @click="edit">Edit</a>
        <a href="#" class="vs-component vs-button mb-md-0 mb-2 ml-2 vs-button-danger vs-button-filled " @click="cancel"><span class="vs-button-text vs-button--text">Cancel</span></a>

      </div>
    </div>

    <vs-popup class="holamundo add-children-popup"  title :active.sync="popupActive">

      <div class="p-10">
        <h2 class="h1 mb-6">Invite Contributors</h2>
        <form class="w-full md:w-1/1">
          <vs-row>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                label-placeholder="First Name"
                data-vv-as="First Name"
                v-model="firstName"
                v-validate="'required'"/>
              <span class="text-danger text-sm" v-show="errors.has('firstName')">{{ errors.first('firstName') }}</span>
            </vs-col>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="lastName"
                name="surName"
                v-validate="'required'"
                label-placeholder="Surname"
              />
              <span class="text-danger text-sm" v-show="errors.has('lastName')">{{ errors.first('lastName') }}</span>
            </vs-col>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="relationToChild"
                name="relationToChild"
                v-validate="'required'"
                label-placeholder="Relation to Child"
              />
              <span class="text-danger text-sm"
                    v-show="errors.has('relationToChild')">{{ errors.first('relationToChild') }}</span>

            </vs-col>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="email"
                name="email"
                v-validate="'required|email'"
                label-placeholder="Email"
                data-vv-as="Email"
              />
              <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
            </vs-col>
          </vs-row>
          <div class="flex mt-5">
            <vs-button class="mr-3 mb-2" @click="submit" :disabled="!isFormValid">Submit</vs-button>
            <vs-button color="warning" type="border" class="mb-2"
                       @click="firstName = surName = relationToChild = email = ''; popupActive = false;">Cancel
            </vs-button>
          </div>
        </form>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import _ from "lodash";

export default {

  data() {
    return {
      popupActive: false,
      firstName: "",
      lastName: "",
      relationToChild: "",
      email: "",
      childId: null,

      pendingInvitations: [],
      activeInvitations: [],
    };
  },
  methods: {
    ...mapActions("parent", [
      "sendAccessEmail",
      "getInvitedList"
    ]),
    submit() {

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          let data = {
            fullName: this.firstName + " " + this.lastName,
            relationToChild: this.relationToChild,
            email: this.email,
            childId: this.childId
          };

          this.sendAccessEmail(data)
            .then((res) => {
              this.getInvitedUserList();
              this.$vs.notify({
                title: 'Success',
                text: "Access Invitation sent Successfully",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
              this.popupActive = false;
              this.$vs.loading.close()
            })
            .catch(err => {
              this.$vs.loading.close()
              console.error(err)
            })
        }
      })
    },
    getInvitedUserList() {
      this.getInvitedList(this.childId)
        .then((res) => {
          let users = res.data.data;

          this.activeInvitations = _.filter(users, function (user) {
            return user.parents.hasProfileAccess == true || user.parents.hasProfileAccess != false;
          });

          this.pendingInvitations = _.filter(users, function (user) {
            return user.parents.hasProfileAccess == false;
          });

        })
        .catch(err => {
          console.error(err)
        })
    },
    initValues() {
      this.firstName = '';
      this.lastName = '';
      this.relationToChild = '';
      this.email = '';
    },
    closePopup() {
      this.popupActive = false;
    },
    invite() {
      this.initValues();
      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.popupActive = true;
    },
    edit() {
      this.$router.push({name: 'access-list-edit'});
    },
    cancel(){
      this.$router.push({name: 'parent-dashboard'});

    },
  },

  created() {
    this.childId = this.currentChild._id;
    this.getInvitedUserList();
  },
  computed: {
    currentChild() {
      return this.$store.state.AppParentSelectedChild;
    },
    isFormValid() {
      return !this.errors.any()
        && this.firstName
        && this.lastName
        && this.relationToChild
        && this.email
    }
  },
  components: {}

}
</script>
